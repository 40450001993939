<template>
  <div class="text-center">
    <div id="nav" class="mt-0 pt-3 pb-3 bg-white border-bottom">
      <h3 class="m-0">Frequently Asked Questions</h3>
    </div>
    <div class="mb-4 p-4 border-bottom">
      <div class="text-justify">
        We get a lot of questions, so we'll post some of the most common ones here.
      </div>
    </div>
  </div>
</template>

<script type="ts">
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class FAQ extends Vue {
}
</script>
