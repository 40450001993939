<template>
  <div style="height: 100%;">
    <FlightsNav style="height: 97px;" />
    <div style="height: -webkit-calc(100% - 97px); height: calc(100% - 97px);">
      <splitpanes class="default-theme" vertical style="height: 100%;">
        <pane size="60">
          <FlightsMap style="height: 100%;" />
        </pane>
        <pane size="40" min-size="25">
          <FlightsTable style="height: 100%;" />
        </pane>
      </splitpanes>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Splitpanes, Pane } from 'splitpanes';
import 'splitpanes/dist/splitpanes.css';

import FlightsMap from '@/components/flights/FlightsMap.vue';
import FlightsNav from '@/components/flights/FlightsNav.vue';
import FlightsTable from '@/components/flights/FlightsTable.vue';

@Component({
  components: {
    Splitpanes,
    Pane,
    FlightsMap,
    FlightsNav,
    FlightsTable,
  },
})
export default class Flights extends Vue {

}
</script>
