<template>
  <div class="h-full bg-white small">
    <b-table
      bordered
      fixed
      responsive="sm"
      small
      :selectable="false"
      sticky-header="100%"
      head-variant="dark"
      :hover="true"
      :items="myProvider()"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      class="h-full"
      :show-empty="true"
      empty-text="Flights are currently loading..."
      empty-filtered-text="No flights match your search criteria."
      >
      <template v-slot:table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{ width: field.key === 'logoUrl' ? '150px' : 'auto' }"
        >
      </template>
      <template v-slot:cell(logoUrl)="data">
        <img v-if="data.value" :src="data.value" alt="Airline Logo"
          style="height: 30.5px; width: 127.5px; object-fit: cover;" />
      </template>
      <template v-slot:cell(flight)="data">
        <router-link :to="`/flights/${data.value.id}`">{{ data.value.flight }}</router-link>
      </template>
      <template v-slot:cell(lastHeard)="data">
        <div class="w-100 text-truncate">{{ data.value }}</div>
      </template>
    </b-table>
  </div>
</template>

<script lang="ts">
import { mapState } from 'vuex';
import { Component, Vue } from 'vue-property-decorator';
import moment from 'moment-timezone';
import { TextFormatter } from '@/utilities/text_formatter';

@Component({
  components: {},
  computed: mapState({
    flights: (state: any) => state.activeFlights,
  }),
})
export default class FlightsTable extends Vue {
  // @Prop() private flights!: Array<any>;

  public flights! : Array<any>;

  sortBy = 'lastHeard';

  sortDesc = true;

  fields = [
    { key: 'logoUrl', label: 'Airline', sortable: false },
    { key: 'airframe', sortable: true },
    {
      key: 'flight',
      sortable: true,
      formatter: (value: any, key: any, item: any) => { // eslint-disable-line arrow-body-style
        return item;
      },
    },
    { key: 'departingAirport', label: 'Departure', sortable: true },
    { key: 'destinationAirport', label: 'Arrival', sortable: true },
    {
      key: 'altitude',
      sortable: true,
      formatter: (value: number) => (value != null ? `${TextFormatter.intToHuman(value)} ft` : ''),
      class: 'text-right',
    },
    {
      key: 'lastHeard',
      sortable: true,
      formatter: (value: any, key: any, item: any) => { // eslint-disable-line arrow-body-style
        return moment(value).fromNow();
      },
    },
  ];

  myProvider() {
    const items = [];
    for (const flight of this.flights) { // eslint-disable-line no-restricted-syntax
      const mergedFlight = {
        logoUrl: flight.airline.logo_url,
        id: flight.id,
        airframe: flight.airframe.tail,
        flight: flight.flight,
        messagesCount: flight.messages_count,
        departingAirport: flight.departing_airport,
        destinationAirport: flight.destination_airport,
        coordinates: flight.latitude ? `${flight.latitude}, ${flight.longitude}` : '',
        altitude: flight.altitude,
        track: flight.track,
        status: flight.status,
        lastHeard: flight.updated_at,
      };
      items.push(mergedFlight);
    }
    return items || [];
  }
}
</script>

<style scoped>

</style>
