<template>
  <div class="home">
    <div id="nav" class="mt-0 pt-3 pb-3 bg-white border-bottom">
      <h3 class="m-0">Airports</h3>
    </div>
    <div class="border-bottom">
      <div class="container p-4">
        Information about all known airports.
      </div>
    </div>
    <div class="container p-4">
      <div class="row row-cols-1 row-cols-md-3">
        <div
          v-for="airport in airports"
          :key="`airport-card-${airport.id}`"
          class="col mb-4"
          >
          <div class="card" style="height: 200px;">
            <div class="card-body">
              <h3 class="">{{ airport.icao }}</h3>
              <div
                class="mb-2 text-muted text-truncate"
                :title="airport.name"
                >{{ airport.name }}</div>
              <div class="small">
                {{ airport.municipality }}
              </div>
              <div class="mb-3 small">
                {{ airport.region }}, {{ airport.country }}
              </div>
              <div :class="{ 'badge-success': airport.status === 'open' }">
                {{ airport.status.toUpperCase() }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mx-auto">
        <button
          v-if="pageNumber > 1"
          class="mr-2 btn btn-primary"
          @click="pageNumber = pageNumber - 1"
          >
          Previous
        </button>
        <div v-if="results" class="d-inline-block mx-2">
          Page {{ pageNumber }} of {{ results.meta.totalPages }}
        </div>
        <button
          v-if="airports.length > 0"
          class="ml-2 btn btn-primary"
          @click="pageNumber = pageNumber + 1"
          >
          Next
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import axios from 'axios';
import Vue from 'vue';
import { Component, PropSync, Watch } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';

@Component({
  components: {
  },
})
export default class Airports extends Vue {
  airports: any[] = [];

  limit = 15;

  pageNumber: number = 1;

  results: any = null;

  // @Watch('$router.query.page')
  // onQueryPageChanged(newValue: string, oldValue: string) {
  //   if (newValue !== oldValue) {
  //     this.setPageFromQuery();
  //   }
  // }

  @Watch('pageNumber')
  onPaged(newValue: number, oldValue: number) {
    if (newValue !== oldValue) {
      const query: any = { ...this.$route.query, page: newValue };
      const newLocation: RawLocation = { query };
      this.$router.push(newLocation);
      this.fetchAirports();
    }
  }

  mounted() {
    this.setPageFromQuery();
    this.fetchAirports();
  }

  async fetchAirports() {
    const response = await axios.get(`${this.$store.state.apiServerBaseUrl}/airports?limit=${this.limit}&page=${this.pageNumber}`);
    const results = response.data;
    this.airports = results.items;
    this.results = results;
  }

  setPageFromQuery() {
    if (this.$route.query.page && !Number.isNaN(Number(this.$route.query.page))) {
      if (Number(this.$route.query.page) !== this.pageNumber) {
        this.pageNumber = Number(this.$route.query.page);
      }
    }
  }
}
</script>
