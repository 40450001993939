<template>
  <div class="home">
    <div id="nav" class="mt-0 pt-3 pb-3 bg-white border-bottom">
      <h3 class="m-0">Airlines</h3>
    </div>
    <div class="border-bottom">
      <div class="container p-4">
        Information about all known airlines.
      </div>
    </div>
    <div class="container p-4">
      <table class="table table-bordered">
        <tbody>
          <tr
            v-for="airline in airlines"
            :key="`airline-row-${airline.id}`"
            >
            <td class="p-2 text-left align-middle bg-white" style="width: 255px;">
              <img
                v-if="airline.logoUrl"
                :src="airline.logoUrl"
                :alt="`Logo for ${airline.name}`"
                class="img-fluid"
                />
            </td>
            <td class="text-left">
              <div class="font-weight-bold">
                {{ airline.name }}
              </div>
              <div class="small">
                <span class="text-muted">ICAO:</span> {{ airline.icao }}
                <span v-if="airline.iata" class="ml-1">
                  <span class="text-muted">IATA:</span> {{ airline.iata }}
                </span>
                <span v-if="airline.prefix" class="ml-1">
                  <span class="text-muted">Prefix:</span> {{ airline.prefix }}
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
import axios from 'axios';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class Airports extends Vue {
  airlines: any[] = [];

  limit = 100;

  pageNumber: number = 1;

  results: any = null;

  // @Watch('$router.query.page')
  // onQueryPageChanged(newValue: string, oldValue: string) {
  //   if (newValue !== oldValue) {
  //     this.setPageFromQuery();
  //   }
  // }

  mounted() {
    this.setPageFromQuery();
    this.fetchAirlines();
  }

  async fetchAirlines() {
    const response = await axios.get(`${this.$store.state.apiServerBaseUrl}/airlines?limit=${this.limit}&page=${this.pageNumber}`);
    const results = response.data;
    this.airlines = results.items;
    this.results = results;
  }

  setPageFromQuery() {
    if (this.$route.query.page && !Number.isNaN(Number(this.$route.query.page))) {
      if (Number(this.$route.query.page) !== this.pageNumber) {
        this.pageNumber = Number(this.$route.query.page);
      }
    }
  }
}
</script>
